body{
    /*background-color: #e9ecef ;*/
    /*background-color: #9a9898 !important;*/
    /*background-color: #70adea !important;*/
}
label {
    display: block;
    margin-top: 10px;
}

.card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
}

.card {
    /*background-color: #fff;*/
    padding: 20px 25px 30px;
    margin: 50px auto 25px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}

#dark .card {
    background-color: #b92626;
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}


.alert-success {
    text-align: center;
}

.alert-danger {
    text-align: center;
}

.jumbotron {
    background-color: #dee2e6 !important;
}

/*.MuiDataGrid-virtualScroller {*/
/*    scrollbar-gutter: stable;*/
/*    scrollbar-width: thin !important;*/
/*    !*background-color: lightgreen !important;*!*/
/*    height: 150px;*/
/*    width: 200px;*/
/*    overflow-y: scroll;*/
/*}*/

/*.container {*/
/*    !*   background: gray; *!*/
/*    width: 100%;*/
/*    max-height: 200px;*/
/*    overflow: auto;*/
/*}*/

/* scrollbar */


::-webkit-scrollbar {
    /*display: none;*/
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background-color: #dfdfdf;
}

::-webkit-scrollbar-thumb {
    width: 4px;
    height: 4px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
}

* {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: none !important;
        background-color: transparent;
    }
    ::-webkit-scrollbar {
        width: 4px !important;
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.3);
    }
}

#light{
    background-color: #e9ecef;
}

#dark{
    background-color: #2b2b2b !important;
}
