body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    /* Height of the footer*/
    height: 30px;
    text-align: center;
    color: #ffffff;
    background: #1976d2;
    box-shadow: 0 -1px 3px 1px rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

* {
    box-sizing: border-box;
}

.HomePage {
    padding: 10px;
    text-align: center;
    position: relative;
}

h1 {
    font-size: 36px;
    color: #333;
}

.message {
    font-size: 18px;
    line-height: 1.5;
}

b {
    font-weight: bold;
}

.instructions {
    margin-top: 20px;
    font-size: 18px;
}

.copyright {
    font-size: 14px;
    padding-bottom: 5px;
}

/*timer*/

.App {
    text-align: center;
    font-family: Arial, sans-serif;
    padding-bottom: 15px;
}

.timer {
    font-size: 2rem;
    margin-top: 20px;
}

.status-bar {
    font-size: 1.5rem;
    margin-top: 20px;
}

button {
    padding: 10px 20px;
    font-size: 1.2rem;
    margin-top: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.yellow-icon {
    color: #d24a03 !important;
}

.green-icon {
    color: green !important;
}

.progress-bar-container {
    width: 100%;
    background-color: #ddd;
    height: 10px;
    margin-top: 20px;
}

.progress-bar {
    width: 0;
    height: 100%;
    background-color: green;
    transition: width 0.5s ease-in-out;
}

/*From PWA*/

.product-card {
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
}

.product-card.accepted {
    background-color: #dfffdf;
}

.product-card.acp-with-com {
    background-color: lightyellow;
}

.product-card.declined {
    background-color: #ffdcdc;
}

/*card style*/
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-card {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center; /* Center the contents horizontally */
}

/*.modal-card p {*/
/*    margin: 0;*/
/*    margin-bottom: 10px;*/
/*}*/

.modal-buttons {
    display: flex;
    justify-content: flex-end;
}

.modal-button {
    padding: 8px 16px;
    margin-left: 10px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.modal-button.cancel {
    background-color: #ddd;
}

.modal-button.confirm {
    background-color: #007bff;
    color: white;
}
/*card style*/


/* Flex layout for the root container */
.product-list-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.product-list-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Media queries for responsive layout */
@media screen and (max-width: 767px) {
    .product-list-container {
        justify-content: center;
    }

    /* Adjust button positions for smaller screens */
    .product-list-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .product-list-buttons > button {
        margin-top: 10px;
    }
}

/*Finish Handover Button*/
.loading-circle {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #000;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
/*Finish Handover Button*/

p {
    margin-bottom: 1rem !important;
    margin-top: auto !important;
}

.MuiDataGrid-columnHeaderTitle {
    /*color: #f50707;*/
    font-weight: bold !important;
}






